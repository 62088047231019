const config = {
  "broker": "1",
  "name": "Russian",
  "registrationBundle": "russian",
  "defaultCountryName": "Russia",
  "defaultCountryCode": "RU",
  "fxgeoip_code": "RU",
  languageCode: 'ru',
  languageName:'Russian',
  spokenLanguageCode:'ru',
  direction:'ltr',
  footerDisclaimer:'787',
  brand: 'iforex'
};
module.exports = config;